import { createAnimation, IonBadge, IonButton, IonCheckbox, IonChip, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonText, IonTextarea, TextareaChangeEventDetail } from '@ionic/react';
import { NodeModel, NodeRender, RenderParams } from '@minoru/react-dnd-treeview';
import { addCircleOutline, calendarOutline, chevronDownOutline, chevronForwardOutline, chevronUpOutline, duplicateOutline, gitMergeOutline, listCircleOutline, locateOutline, reorderThreeOutline, returnDownForwardOutline, trashBin, trashBinOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ADDTYPE, NodeData } from '../services/Todo';
import { format, parseISO } from 'date-fns';

import './TodoStyle.css'
import { getDateDiffFromNow, getDateDiffFromNowDate } from '../Utils';
import { FocusContext, FocusContextProps } from './TodoListView';

export type TaskParams = {
    node: NodeModel<NodeData>,
    params: RenderParams,
    onUpdateTodo: (updatedTodo: NodeModel<NodeData>) => void,
    onAddnew: (id: string | number, addType: ADDTYPE, currentTodo: NodeModel<NodeData> | null) => void,
    newTodo: NodeModel<NodeData> | undefined,
    onOpenModal: (node: NodeModel<NodeData>) => void,
    counterSummary: [number, string[]]
}

const Task: React.FC<TaskParams> = ({ node, params, onAddnew, newTodo, onUpdateTodo, onOpenModal, counterSummary }) => {

    const [showTodoMenu, setShowTodoMenu] = useState(false);
    const [showInfoDetail, setShowInfoDetail] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [todoText, setTodoText] = useState(node.text);
    const itemGridRef = React.useRef<HTMLIonGridElement>(null);
    const { focusedId, setFocusedId } = useContext(FocusContext) as FocusContextProps;
    const inputEl = useRef<HTMLIonInputElement>(null);


    useEffect(() => {
        if (inputEl !== null && newTodo && newTodo.id == node.id) {
            inputEl.current?.setFocus();
        }
    }, []);

    function handleAddNew(id: string | number) {
        onAddnew(id, ADDTYPE.FIRST, node);
        if (!params.isOpen)
            params.onToggle();
    }

    function handleTextChange(event: CustomEvent) {
        let value = event.detail.value;
        // If the input is not empty and it is the first character, capitalize it.
        if (value && value.length === 1) {
           // value = value.toUpperCase();
        }
        setTodoText(value);
        const updatedTodo = { ...node, text: event.detail.value };
        console.log("in text change");
        onUpdateTodo(updatedTodo);
    }

    function handleCheckboxChange(id: string | number) {
        const updatedTodo = {
            ...node,
            data: node.data ? {
                ...node.data,
                completed: !node.data?.completed
            } : {
                completed: true,
                create_date: Date.now() + "",
                update_date: Date.now() + ""
            }
        }
        onUpdateTodo(updatedTodo);
    }

    function formatDateToStore(value: Date): string {
        return format(value, 'yyyy-MM-dd');
    }


    function getWarningType(dueDate: string): string {
        const dayDiff = getDateDiffFromNow(dueDate);
        if (dayDiff < 0) {
            return "danger";
        } else if (dayDiff > 0 && dayDiff < 3) {
            return "warning"
        } else {
            return "medium";
        }
    }

    function formatDateToShow(value: Date): string {
        try {
            const dayDiff = getDateDiffFromNowDate(value);
            console.log(dayDiff);
            if (dayDiff < 0) {
                return ' Today';
            } else if (dayDiff < 1) {
                return ' Tomorrow';
            }
            return format(value, 'MMM d');
        } catch (e) {
            console.log(e)
            return "";
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            onAddnew(node.parent, ADDTYPE.NEXT, node);
        }
    };

    const handleItemPlayAnimation = (type = 0) => {
        if (itemGridRef.current && itemGridRef.current !== null) {
            const animation = createAnimation()
                .addElement(itemGridRef.current)
                .duration(500).fromTo('opacity',
                    '1', '0').easing('ease-out');
            animation.play();
            animation.afterClearStyles(['opacity']);
            animation.onFinish(() => { handleCheckboxChange(node.id); animation.stop() });
        }
    };

    {
        return (
            <IonGrid style={params.depth > 0 ? { borderLeft: '1px solid #EEE' }
                : {}} ref={itemGridRef} onFocus={() => setFocusedId(node.id)} >
                <IonRow class="ion-align-items-center">
                    <IonCol size='auto' style={{ paddingRight: '0.4rem' }}>
                        <IonCheckbox checked={node.data?.completed}
                            onClick={(event) => { event.stopPropagation(); console.log("check clicked"); handleItemPlayAnimation(); }}>
                        </IonCheckbox>
                    </IonCol>
                    <IonCol>
                        <IonGrid>
                            <IonRow class="ion-align-items-center">
                                <IonCol>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IonInput
                                            ref={inputEl}
                                            enterkeyhint={'done'}
                                            className="ion-no-padding ion-no-margin ion-text-capitalize"
                                            placeholder="New task"
                                            value={todoText}
                                            onIonChange={handleTextChange}
                                            onKeyPress={handleKeyPress}
                                            style={node.data?.completed ? { textDecoration: 'line-through', color: 'lightgray'} : (params.hasChild && !params.isOpen) ? { fontWeight: 400} : {}}
                                        />
                                       {/* <div style={{display: "inline-block"}}>
                                                 {   node.data?.due_date && node.data?.due_date !== "" && !node.data?.completed &&
                                                   <IonText color={getWarningType(node.data?.due_date)} style={{ fontSize: 'x-small'}}> {formatDateToShow(new Date(node.data?.due_date + "T00:00:00"))}</IonText>
                                                   }
                                              </div>
                                                */}    
                                    {params.hasChild &&
                            <div>
                                <IonText onClick={params.onToggle} color={"medium"}>
                                    {
                                        counterSummary[0] > 0 ? `${counterSummary[0]}` : ''
                                    }
                                    <IonIcon icon={params.isOpen ? chevronUpOutline : chevronDownOutline} />
                                </IonText>
                            </div>
                         
                    }
                    </div>
                                </IonCol>
                            </IonRow>
                            {!params.isOpen && params.hasChild && <IonRow>
                                <IonCol>
                                    <IonLabel style={{ "fontSize": "0.8rem" }}>{counterSummary[1].join(", ")}</IonLabel>
                                </IonCol>
                            </IonRow>
                            }
                        </IonGrid>
                    </IonCol>

                </IonRow>

                {(focusedId === node.id) &&
                    <IonRow class="ion-align-items-bottom" style={{ padding: '0.4rem', }}>
                        <IonCol>
                            <IonText tabIndex={0} color={'medium'} style={{ marginRight: '0.8rem', fontSize: 'small', border: '1px solid #EBE4FF', borderRadius: '0.4rem', padding: '0.3rem' }} class="ion-align-items-center"
                                onClick={() => { onOpenModal(node) }}>
                                <IonIcon icon={calendarOutline} />
                                {(node.data?.due_date && node.data?.due_date !== "" && !node.data?.completed) ? ' ' + formatDateToShow(new Date(node.data?.due_date + "T00:00:00")) : ''}
                            </IonText>

                            {params.depth < 2 && focusedId === node.id && <IonText tabIndex={0} color={'medium'} style={{ marginRight: '0.8rem', fontSize: 'small', border: '1px solid #DDD', borderRadius: '0.4rem', padding: '0.2rem' }}
                                onClick={(e) => { handleAddNew(node.id) }} >
                                <IonIcon icon={gitMergeOutline} />
                            </IonText>
                            }  
                                <IonText color={'medium'} tabIndex={0} style={{ marginRight: '1rem', fontSize: 'small', border: '1px solid #DDD', borderRadius: '0.4rem', padding: '0.2rem' }}>
                                    <IonIcon icon={trashBinOutline} />

                                </IonText>
                            
                        </IonCol>
                    </IonRow>
                }
            </IonGrid>
        )
    }
}

export default Task;