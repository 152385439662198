import React, { useEffect, useRef, useState } from 'react';
import {
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonAvatar,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    IonDatetime,
} from '@ionic/react';
import { format, parseISO } from 'date-fns';

import './DateModal.css';

type DueDateSelectorModalProps = {
    defaultDueDate?: string;
    openModal: boolean;
    onDismiss: () => void;
    onChange?: (newValue: string | undefined) => void;
};

const DateModal: React.FC<DueDateSelectorModalProps> = ({ defaultDueDate, openModal = false, onDismiss, onChange }) => {
    const modal = useRef<HTMLIonModalElement>(null);
    const [dueDate, setDueDate] = useState<string | undefined>(defaultDueDate);
    const datetime = useRef<null | HTMLIonDatetimeElement>(null);
    const [isOpen, setIsOpen] = useState(openModal);
    console.log(openModal);
    useEffect(() => {
        setIsOpen(openModal);
        console.log(openModal);
    }, [openModal]);

    useEffect(() => {
        onChange && onChange(dueDate);
    }, [dueDate]);

    function dismiss() {
        modal.current?.dismiss();
        setIsOpen(false);
        onDismiss();
    }

    function endOfWeek() {
        const today = new Date();
        // set the start day to the next Saturday
        const startDay = today.getDate() + (6 - today.getDay());
        const weekend = formatDateToStore(new Date(today.setDate(startDay)));
        setDueDate(weekend);
        dismiss();
    }

    function tomorrow() {
        const today = new Date();
        // set the start day to the next Saturday
        const startDay = today.getDate() + 1;
        const tom = formatDateToStore(new Date(today.setDate(startDay)));
        setDueDate(tom);
        dismiss();
    }

    function nextWeek() {
        const today = new Date();
        // set the start day to the next Saturday
        const startDay = today.getDate() + (6 - today.getDay()) + 2;
        const next_week = formatDateToStore(new Date(today.setDate(startDay)));
        console.log(next_week);
        setDueDate(next_week);
        dismiss();
    }

    function today() {
        setDueDate(formatDateToStore(new Date()));
        dismiss();
    }

    function formatDateToStore(value: Date): string {
        return format(value, 'yyyy-MM-dd');
    }

    return (

        <IonModal id="alert-modal" ref={modal} isOpen={isOpen} onIonModalDidDismiss={dismiss}>
            <div className="wrapper">
            <h1>Select date</h1>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol size='auto'>
                                <IonButton size="small" shape='round' onClick={today}>Today</IonButton> <IonButton size="small" shape='round' onClick={tomorrow}>Tomorrow</IonButton>
                                <IonButton size="small" shape='round' onClick={endOfWeek}>This Weekend</IonButton><IonButton size="small" shape='round' onClick={nextWeek}>Next Week</IonButton>
                                <IonButton size="small" shape='round' color="danger" onClick={() => { setDueDate(""); dismiss(); }}>No Date</IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonDatetime showDefaultButtons={true} ref={datetime} id="datetime" presentation='date' value={dueDate} onIonChange={e => {
                                    console.log(e.detail.value?.toString());
                                    setDueDate(e.detail.value?.toString().substring(0, 10)!);
                                }} >
                                </IonDatetime>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>
                </div>
        </IonModal>

    );
}

export default DateModal;