import { createAnimation, IonBadge, IonButton, IonButtons, IonCheckbox, IonChip, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPopover, IonRow, IonText, IonTextarea, IonToolbar } from '@ionic/react';
import { NodeModel, NodeRender, RenderParams } from '@minoru/react-dnd-treeview';
import { addCircleOutline, addOutline, calendarOutline, chevronDownOutline, chevronForwardOutline, chevronUpOutline, duplicateOutline, fileTrayFullOutline, gitMergeOutline, listCircleOutline, locateOutline, pricetagsOutline, reorderThreeOutline, returnDownForwardOutline, timeOutline, trashBin, trashBinOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { getNewTaskTemplate, NodeData } from '../services/Todo';
import { format, parseISO } from 'date-fns';

import './MainHeaderView.css'
import { getDateDiffFromNow, LABELS, SCHEDULED } from '../Utils';
import { useLocalStorage } from '../services/useLocalStorage';
import { defaultLabels, Label } from '../services/Label';
import { defaultSchedules, Scheduled } from '../services/Scheduled';

export type TaskParams = {
    focusLabel: Label,
    onAddnew: () => void,
    handleLabel: (label: Label) => void
}

const MainHeader: React.FC<TaskParams> = ({ focusLabel, onAddnew, handleLabel }) => {
    const [newTodoText, setNewTodoText] = useState('');
    const itemAnimationRef = useRef<HTMLIonGridElement>();
    const [labels, setLabels] = useLocalStorage<Label[]>(LABELS, defaultLabels);
    const [scheduled, setScheduled] = useLocalStorage<Scheduled[]>(SCHEDULED, defaultSchedules);
    function formatDateToStore(value: Date): string {
        return format(value, 'yyyy-MM-dd');
    }

    const handleNewClick = () => {
        onAddnew && onAddnew();
    };

    const handleLabelClick = (clickedLabel: Label) => {
        handleLabel(clickedLabel);
    }

    const handleNewTextChange = (value: string) => {
        setNewTodoText(value);
    }

    {
        return (
<>
                <IonItem lines='none'>
                <div className="chip-container">
                        {labels.map((lbl: Label, index: number) => {
                            return <IonChip color={focusLabel.id==lbl.id ? 'tertiary': 'medium'} 
                            onClick={(event) => { event.stopPropagation(); handleLabelClick(lbl); }}>
                            
                            <IonIcon icon={lbl.icon}></IonIcon>
                            <IonLabel>{lbl.name}</IonLabel></IonChip>
                        })}
                 </div>   

                <IonChip color={'dark'} slot='end' id="date-trigger">
                    <IonText color={'dark'}>
                        <IonIcon icon={fileTrayFullOutline} /> Any Date<IonIcon icon={chevronDownOutline} />
                    </IonText>
                    </IonChip>
                <IonPopover trigger="date-trigger" triggerAction="click">
                    <IonList>
                        {scheduled.map((sch: Scheduled, index: number) => {
                            return <IonItem button>
                                <IonIcon color={sch.color} slot="start" icon={sch.icon}></IonIcon>
                                <IonLabel>{sch.name}</IonLabel></IonItem>
                        })}
                    </IonList>
                </IonPopover>
                {/*
                <IonButtons slot='start'>
                <IonChip 
                    color={"dark"} slot='start'>
                    <IonText color={'dark'}>
                    <IonIcon onClick={onAddnew} icon={addOutline} color='tertiary'/>New 
                    
                    </IonText>
                </IonChip>
                
                </IonButtons>
                    */}
                    </IonItem>
</>
        )
    }
}

export default MainHeader;