import { IonAccordion, IonAccordionGroup, IonAlert, IonApp, IonAvatar, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonPage, IonPopover, IonProgressBar, IonRow, IonSegment, IonSegmentButton, IonSlide, IonSlides, IonSplitPane, IonText, IonTextarea, IonTitle, IonToolbar, isPlatform } from "@ionic/react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { add, addCircleOutline, addOutline, calendarOutline, chevronDownOutline, ellipsisHorizontal, ellipsisHorizontalOutline, ellipsisVertical, ellipsisVerticalOutline, gitMergeOutline, homeOutline, hourglassOutline, laptopOutline, listOutline, personCircle, pricetagOutline, pricetagsOutline, search, timeOutline, trashBinOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import TodoListView from "./components/TodoListView";
import { FocusList, defaultFocusList, getNewFocusListTemplate } from "./services/FocusList";
import { defaultLabels, Label, TAGTYPE } from "./services/Label";
import { ADDTYPE, DEFAULT_TODO, getNewTaskTemplate, NodeData } from "./services/Todo";
import { useLocalStorage } from "./services/useLocalStorage";
import './Home.css'
import { countChildren, COUNTTYPE, LABELS, minTwoDigits, Scheduled, ScheduledType, TODOS } from "./Utils";
import AlertModal from "./components/AlertModal";
import { SearchBar } from "./components/SearchBar";
import { normalize, schema } from 'normalizr';
import Timer from "./components/Timer";
import { useSingleArrayTodoService } from "./services/useSingleArrayTodoService";
import NewTodoView from "./components/NewTodoView";
import MainHeader from "./components/MainHeaderView";

function Home() {

    const [isLoading, setIsLoading] = useState(false);
    const [labels, setLabels] = useLocalStorage<Label[]>(LABELS, defaultLabels);
    const [focusLabel, setFocusLabel] = useState<Label>(labels.filter(label => label.default)[0]);
    const todoService = useSingleArrayTodoService();
    const todos = todoService.todos;
    const [editMode, setEditMode] = useState(false);
    const [isOpenAddListModal, setIsOpenAddListModal] = useState(false);
    const [isNewListAlertOpen, setIsNewListAlertOpen] = useState(false);

    function getDeviceDpi() {
        const pixelRatio = window.devicePixelRatio;
        // A typical assumption is that 1 CSS inch is equal to 96 CSS pixels.
        const dpi = pixelRatio * 96;
        return dpi;
    }



    function handleFocusChangeTag(clickedTag: Label) {
        setEditMode(false);
        const newTags = labels.map((tag) => {
            if (tag.id === clickedTag.id) {
                return { ...tag, default: true };
            } else {
                return { ...tag, default: false };
            }
        });
        setFocusLabel(clickedTag);
        setLabels(newTags);
    }

    function updateEditMode() {
        if (editMode) {
            //handleUpdateCategoryName(focusCategory.id);
        }
        setEditMode(!editMode);
    }

    const handleOnAddNewTodoEmpty = () => {
        const newTodo = { ...getNewTaskTemplate(0, focusLabel) };
        todoService.addTodo(newTodo, ADDTYPE.FIRST, null);
    };

    function filterOpenTodos() {
        if(focusLabel.type === TAGTYPE.ALL) {
            return todoService.openTodos;
        }
        return todoService.openTodos.filter(todo => 
            todo.data?.labelId === focusLabel.id
        )
    }

    const handleOnUpdateTodo = (updateTodo: NodeModel<NodeData>) => {
        todoService.updateTodo(updateTodo);
    };

    const handleOnLabelClick = (label: Label) => {
        setFocusLabel(label);
    };

    return (isLoading ? <IonLabel color="none">Loading...</IonLabel> :
        <>
            <div className="ion-page" id="main">
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonTitle><b>Focus</b>List</IonTitle>
                        </IonButtons>
                        <IonButtons slot="end">
                            <IonButton>
                                <IonIcon slot="icon-only" icon={personCircle}></IonIcon>
                            </IonButton>
                            <IonButton>
                                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonGrid style={{ "height": "100%" }} className="ion-no-padding" fixed={true}>
                    <IonRow style={{ "height": "98%" }}>
                        <IonCol size="12" >
                            <IonContent scrollY={true} style={{ "height": "100%" }} class="ion-padding-horizontal" >
                                <MainHeader focusLabel={focusLabel} onAddnew={handleOnAddNewTodoEmpty} handleLabel={handleOnLabelClick} />
                                <TodoListView initialTasks={filterOpenTodos()} todoService={todoService} focusLabel={focusLabel} />
                            </IonContent>
                        </IonCol></IonRow>
                </IonGrid>
                <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton onClick={handleOnAddNewTodoEmpty}>
                        <IonIcon icon={add}></IonIcon>
                    </IonFabButton>
                </IonFab>
                {/*
        <IonFooter id="item-footer" translucent={true}>
            <NewTodo defaultCategoryValue={focusCategory.id} onSubmit={handleAddTask} />
        </IonFooter>
                        */}
            </div>
        </>
    );

}

export default Home;