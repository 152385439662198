import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IonInput, IonItem, IonIcon, IonButton, IonReorderGroup, IonReorder, IonCheckbox, IonLabel, IonItemSliding, IonItemOptions, IonItemOption, IonGrid, IonRow, IonCol } from '@ionic/react';
import { addCircleOutline, arrowUpCircle, arrowDownCircle, trashOutline, chevronDownOutline, chevronForwardOutline } from 'ionicons/icons';

import { DndProvider } from "react-dnd";
import {
    Tree,
    MultiBackend,
    getBackendOptions,
    NodeModel
} from "@minoru/react-dnd-treeview";

import './TodoListStyle.css'
import { Placeholder } from './PlaceHolder';
import { DragPreview } from './DragPreview';
import { ADDTYPE, getNewTaskTemplate, NodeData, TodoService } from '../services/Todo';
import { countChildren, COUNTTYPE, uniqueID } from '../Utils';
import DateModal from './DateModal';
import { TouchBackendOptions } from 'react-dnd-touch-backend';
import { HTML5BackendOptions } from 'react-dnd-html5-backend';
import TodoView from './TodoViewV1';
import { Label } from '../services/Label';

export interface FocusContextProps {
    focusedId: string | number;
    setFocusedId: React.Dispatch<React.SetStateAction<string | number>>;
}

export const FocusContext = React.createContext<FocusContextProps | undefined>(undefined);

const TodoListView: React.FC<{ initialTasks: NodeModel<NodeData>[], todoService: TodoService, focusLabel: Label }> = ({ initialTasks, todoService, focusLabel }) => {
    const [tasks, setTasks] = useState<NodeModel<NodeData>[]>(initialTasks);
    const handleDrop = (newTree: NodeModel<NodeData>[]) => setTasks(newTree);
    const [openModalNode, setOpenModalNode] = useState<NodeModel<NodeData> | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [focusedId, setFocusedId] = useState<string | number>('');
    const containerRef = useRef<HTMLDivElement>(null);
    const [newTask, setNewTask] = useState<NodeModel<NodeData>>();

    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (containerRef.current && !openModal && !containerRef.current.contains(event.target as Node)) {
            console.log(event.target);
            setFocusedId('');
        }
      };
    
      useEffect(() => {
        //document.addEventListener('mousedown', handleClickOutside);
        //document.addEventListener('touchstart', handleClickOutside);
        return () => {
          //document.removeEventListener('mousedown', handleClickOutside);
          //document.removeEventListener('touchstart', handleClickOutside);
        };
      }, []);



    useEffect(() => {
        setTasks(initialTasks);
    }, [initialTasks]);

    const handleInputChange = (id: number | string, text: string) => {
        const newTasks = tasks.map(task => task.id === id ? { ...task, text } : task);
        setTasks(newTasks);
        //onTasksChange && onTasksChange(newTasks);
    };

    const handleAddNew = (parent: string | number, addType: ADDTYPE, currentTodo: NodeModel<NodeData> | null) => {
        const newTask = getNewTaskTemplate(parent, focusLabel);
        setNewTask(newTask);
        todoService.addTodo(newTask, addType, currentTodo);
        //onTasksChange && onTasksChange(newTasks);
    };


    const handleDeleteTask = (id: number) => {
        const newTasks = tasks.filter(task => task.id !== id && task.parent !== id);
        setTasks(newTasks);
        //onTasksChange && onTasksChange(newTasks);
    };

    const handleUpdatedTodo = (todo: NodeModel<NodeData>) => {
        //setInputValue(e.target.value);
        todoService.updateTodo(todo);
    }

    //const handleUpdatedTodo = (todo: NodeModel<NodeData>) => {
    //   todoService.updateTodo(todo);
    //onTasksChange && onTasksChange(newTasks);
    //};

    const handleOnOpenModal = (node: NodeModel<NodeData>) => {
        console.log("in handle open modal::" + node);
        setOpenModalNode(node);
        setOpenModal(true);
    }

    const handleOnCloseModal = () => {
        setOpenModal(false);
    }

    const handleDueDateChangeModal = (dueDate: string | undefined) => {
        if (openModalNode) {

            const updatedTodo = {
                ...openModalNode,
                data: openModalNode.data ? {
                    ...openModalNode.data,
                    due_date: dueDate
                } : {
                    completed: false,
                    due_date: dueDate,
                    create_date: Date.now() + "",
                    update_date: Date.now() + ""
                }
            }

            todoService.updateTodo(updatedTodo);
            //onTasksChange && onTasksChange(newTasks);
            setOpenModalNode(null);
        }
    }

    const touchOptions: Partial<TouchBackendOptions> = {
        // some options
        delay: 500, delayTouchStart: 500, delayMouseStart: 500
    };

    const html5Options: Partial<HTML5BackendOptions> = {
        rootElement: document.body,
        // some options
    };

    const multiOptions = {
        touch: touchOptions,
        html5: html5Options,
    }

    const renderNode = (parent: number) => {
        return (
            <FocusContext.Provider value={{ focusedId, setFocusedId }}>
                <div className="app" ref={containerRef}>
                    {/* style={{background: 'linear-gradient(to bottom, rgb(255,216,215), rgb(254,192,193))'}} */}
                    <DndProvider backend={MultiBackend} options={{ ...getBackendOptions(multiOptions) }} >
                        <Tree
                            tree={tasks}
                            rootId={parent}
                            sort={false}
                            insertDroppableFirst={false}
                            dropTargetOffset={10}
                            canDrop={(tree, { dragSource, dropTargetId }) => {
                                if (dragSource?.parent === dropTargetId) {
                                    return true;
                                }
                            }}
                            render={(node, params) => (
                                    <TodoView node={node}
                                        params={params}
                                        onAddnew={handleAddNew}
                                        newTodo={newTask}
                                        onUpdateTodo={handleUpdatedTodo}
                                        onOpenModal={handleOnOpenModal}
                                        counterSummary={countChildren(tasks, node.id, COUNTTYPE.ALL)}
                                    />

                            )}

                            placeholderRender={(node, { depth }) => (
                                <Placeholder node={node} depth={depth} />
                            )}
                            onDrop={handleDrop}
                            initialOpen={true}
                            classes={{
                                root: "treeRoot",
                                draggingSource: "draggingSource",
                                placeholder: "placeholderContainer"
                            }}

                        />
                    </DndProvider>
                    <DateModal openModal={openModal} onDismiss={handleOnCloseModal} onChange={handleDueDateChangeModal} />
                </div>
            </FocusContext.Provider>
        );
    }


    return (

        renderNode(0)

    );
};

export default TodoListView;
