import { bagAddOutline, calendarOutline, laptopOutline, pricetagOutline, timeOutline, todayOutline } from "ionicons/icons";

export enum TAGTYPE {
    SINGLE, ALL
}
export interface Scheduled {
    id: number;
    name: string;
    desc: string;
    color?: string;
    default: boolean | 'hidden';
    type: TAGTYPE;
    icon?: string;

}

export const defaultSchedules: Scheduled[] = 
    [{
        id: 0,
        name: "Any Date",
        desc: "",
        default: 'hidden',
        type: TAGTYPE.ALL,
        icon: timeOutline,
        color: 'dark'
    },
        {
        id: 1,
        name: "Today",
        desc: "",
        default: true,
        type: TAGTYPE.SINGLE,
        icon: todayOutline,
        color: 'danger'
    },
    {
        id: 2,
        name: "Upcoming",
        desc: "",
        default: false,
        type: TAGTYPE.SINGLE,
        icon: calendarOutline,
        color: 'primary'
    },
];