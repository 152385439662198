import { useEffect, useState } from "react";

export function useLocalStorage<T>(key: string, defaultValue: T): [T, (newValue: T) => void] {
    const local = window.localStorage.getItem(key);
    const [value, setValue] = useState<T>(local ? JSON.parse(local) : defaultValue);

    useEffect(() => {
        async function getStoredValue() {
            const storedValue = window.localStorage.getItem(key);
            setValue(storedValue ? JSON.parse(storedValue) : defaultValue);
        }
        getStoredValue();
    }, [key, defaultValue]);

    function updateStorage(newValue: T) {
        window.localStorage.setItem(key, JSON.stringify(newValue));
        setValue(newValue);
    }

    return [value, updateStorage];
}