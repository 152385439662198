import { NodeModel } from "@minoru/react-dnd-treeview";
import { TODOS } from "../Utils";
import { Label } from "./Label";
import { ADDTYPE, DEFAULT_TODO, getNewTaskTemplate, NodeData, TodoService } from "./Todo";
import { useLocalStorage } from "./useLocalStorage";

export function useSingleArrayTodoService(): TodoService {
    const [todos, setTodos] = useLocalStorage<NodeModel<NodeData>[]>(TODOS, DEFAULT_TODO);
    
    const openTodos = todos.filter(todo => !todo.data?.completed);
    const completedTodos = todos.filter(todo => todo.data?.completed);
    const todosByLabel = (labelId: Label["id"]) => todos.filter(todo => todo.data?.labelId === labelId)
    
    const addTodo = (todo: NodeModel<NodeData>, addType: ADDTYPE, currentTodo: NodeModel<NodeData> | null = null) => {
        const newTodos = addType == ADDTYPE.FIRST ? [todo, ...todos] 
        : addType == ADDTYPE.LAST ? [...todos, todo] : currentTodo==null ? todos : todos.flatMap(item => {
            if (item.id === currentTodo.id) {
                return [item, todo];
              }
              return item;
        })
        setTodos(newTodos);
    };

    const updateTodo = (updatedTodo: NodeModel<NodeData>) => {
        const updatedTodos = todos.map(t => t.id === updatedTodo.id ? updatedTodo : t);
        setTodos(updatedTodos);
    };

    const deleteTodo = (id: number) => {
        const updatedTodos = todos.filter(t => t.id !== id);
        setTodos(updatedTodos);
    };

    return {
        todos,
        openTodos,
        completedTodos,
        todosByLabel,
        addTodo,
        updateTodo,
        deleteTodo
    };

}
