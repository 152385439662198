import { NodeModel } from "@minoru/react-dnd-treeview";
import { uniqueID } from "../Utils";
import { Label, TAGTYPE } from "./Label";

export interface NodeData {
    details?: string;
    labelId?: Label["id"];
    completed: boolean;
    create_date: string;
    update_date: string;
    due_date?: string;
    time?: string;
    reminder?: boolean;
    reminder_type?: string;
    reminder_at?: string;
    repeat?: boolean;
    order?: number;
}

export enum ADDTYPE {
    FIRST, NEXT, LAST
}


export function getNewTaskTemplate(parent: string | number, focusLabel: Label): NodeModel<NodeData> {
    return {
        id: uniqueID(),
        text: "",
        parent,
        droppable: true,
        ...(focusLabel.type === TAGTYPE.ALL ? {data: {
            completed: false,
            create_date: Date.now() + "",
            update_date: Date.now() + ""
        }} : { data: {
            labelId: focusLabel.id,
            completed: false,
            create_date: Date.now() + "",
            update_date: Date.now() + ""
        }})
    };
}

export interface TodoService {
    todos: NodeModel<NodeData>[];
    openTodos: NodeModel<NodeData>[];
    completedTodos: NodeModel<NodeData>[];
    todosByLabel(labelId: Label["id"]): NodeModel<NodeData>[];
    addTodo(todo: NodeModel<NodeData>, addType: ADDTYPE, currentTodo: NodeModel<NodeData> | null): void;
    updateTodo(updatedTodo: NodeModel<NodeData>): void;
    deleteTodo(id: number): void;
}

export const DEFAULT_TODO: NodeModel<NodeData>[] = [
    {
        id: 1,
        text: "Submit Project Report",
        parent: 0,
        droppable: true,
        data: {
            details: "",
            labelId: 2,
            completed: false,
            create_date: "2023-04-01T20:26:38.700Z",
            update_date: "2023-04-01T20:26:38.700Z",
            due_date: "2023-04-01",
            order: 1,
        }
    },
    {
        id: 5,
        text: "Complete work on data analysis",
        parent: 1,
        droppable: true,
        data: {
            details: "",
            labelId: 2,
            completed: false,
            create_date: "2023-04-01T20:26:38.700Z",
            update_date: "2023-04-01T20:26:38.700Z",
            due_date: "2023-04-01",
            order: 1,
        }
    },
    {
        id: 2,
        text: "Pay Rent for April",
        parent: 0,
        droppable: true,
        data: {
            details: "",
            labelId: 1,
            completed: false,
            create_date: "2023-04-01T20:26:38.700Z",
            update_date: "2023-04-01T20:26:38.700Z",
            due_date: "2023-04-01",
            time: "8:30",
            reminder: true,
            reminder_type: "notification",
            reminder_at: "5 min",
            repeat: true,
            order: 2,
        }
    },
    {
        id: 3,
        text: "Pay Rent for April",
        parent: 0,
        droppable: true,
        data: {
            details: "",
            labelId: 1,
            completed: false,
            create_date: "2023-04-01T20:26:38.700Z",
            update_date: "2023-04-01T20:26:38.700Z",
            due_date: "2023-04-01",
            time: "8:30",
            reminder: true,
            reminder_type: "notification",
            reminder_at: "5 min",
            repeat: true,
            order: 3,
        }
    },
    {
        id: 4,
        text: "Read book on trading: Top Trader",
        parent: 0,
        droppable: true,
        data: {
            details: "",
            labelId: 1,
            completed: false,
            create_date: "2023-04-01T20:26:38.700Z",
            update_date: "2023-04-01T20:26:38.700Z",
            due_date: "2023-04-01",
            time: "8:30",
            reminder: true,
            reminder_type: "notification",
            reminder_at: "5 min",
            repeat: true,
            order: 3,
        }
    },
];