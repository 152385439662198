import { bagAddOutline, fileTrayFullOutline, laptopOutline, pricetagOutline } from "ionicons/icons";

export enum TAGTYPE {
    SINGLE, ALL
}
export interface Label {
    id: number;
    name: string;
    desc: string;
    color?: string;
    default: boolean | 'hidden';
    type: TAGTYPE;
    icon?: string;
}

export const defaultLabels: Label[] = 
    [{
        id: 0,
        name: "All Tasks",
        desc: "",
        default: 'hidden',
        type: TAGTYPE.ALL,
        icon: fileTrayFullOutline
    },
        {
        id: 1,
        name: "Personal",
        desc: "",
        default: true,
        type: TAGTYPE.SINGLE,
        icon: bagAddOutline,
        color: 'primary'
    },
    {
        id: 2,
        name: "Work",
        desc: "",
        default: false,
        type: TAGTYPE.SINGLE,
        icon: laptopOutline,
        color: 'primary'
    },
];