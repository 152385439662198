import { NodeModel } from "@minoru/react-dnd-treeview";
import { pricetagOutline } from "ionicons/icons";
import { NodeData } from "./services/Todo";

export function uniqueID() {
    return Math.floor(Math.random() * Date.now())
}

export const LABELS = "labels";
export const TODOS = "todos";
export const SCHEDULED = "scheduled";

export interface ScheduledType {
    id: number;
    name: string;
    icon?: string;
}

export interface TreeSummary {
    count: number;
    childSummary: string[];
}

export const Scheduled: ScheduledType[] = [
    {
        id: 1, name: "All"
    },
    {
        id: 2, name: "Today"
    }, {
        id: 3, name: "This Week"
    }, {
        id: 3, name: "This Month"
    }]


export function getDateDiffFromNow(dueDate: string): number {
    const due = new Date(dueDate + "T00:00:00").getTime() / 1000;
    const now = new Date().getTime() / 1000;
    const final = (due - now) / 86400;
    return final;
}

export function getDateDiffFromNowDate(dueDate: Date): number {
    const due = dueDate.getTime() / 1000;
    const now = new Date().getTime() / 1000;
    const final = (due - now) / 86400;
    return final;
}

export function minTwoDigits(n: number) {
    return (n < 10 ? '0' : '') + n;
}

export enum COUNTTYPE {
    ALL, COMPLETED, NOTCOMPLETED
}

export function countChildren(
    tasks: NodeModel<NodeData>[],
    parentId: number | string,
    countType: COUNTTYPE
): [number, string[]] {
    let count = 0;
    let summary: string[] = [];

    for (let task of tasks) {
        if (task.parent === parentId) {
            if (
                countType === COUNTTYPE.ALL ||
                (countType === COUNTTYPE.COMPLETED && task.data?.completed) ||
                (countType === COUNTTYPE.NOTCOMPLETED && !task.data?.completed)
            ) {
                if (count < 2 && task.text && task.text !== "") {
                    summary = [...summary, task.text];
                }
                count++;
            }

            // If the current task is a parent of other tasks, recursively count its children
            const [childCount, childSummary] = countChildren(tasks, task.id, countType);
            count += childCount;
            summary = [...childSummary, ...summary];
        }
    }

    return [count, summary];
}