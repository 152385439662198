import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useEffect } from 'react';
import { createStore } from './store/IonicStorage';

import NestedInputComponentV2 from './pages/v2/components/NestdInputComponentV2';
import TaskList from './pages/v3/components/tasks/TaskList';
import Home from './pages/v3/Home';
import HomeV1 from './pages/v3/HomeV1';
import HomeV2 from './pages/v3/HomeV2';
import Main from './pages/v4/Main';

//import { ScreenOrientation } from '@ionic-native/screen-orientation';

setupIonicReact();

const App: React.FC = () => {

  const lockOrientation = async () => {
    try {
      //await ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    } catch (error) {
      console.log('ScreenOrientation lock error:', error);
    }
  };

  useEffect(() => {
    lockOrientation();
    return () => {
      //ScreenOrientation.unlock();
    };
  }, []);

	/*useEffect(() => {

		const setupStore = async () => {
			await createStore("TaskEaseDB");
		};
		setupStore();
	}, []); */
  return(
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Main/>
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
  );
};

export default App;
